import React from "react";
import "./_NotifItem.scss";
import { timeConversion } from "../../utilities/timeConversion";
import { useSelector } from "react-redux";
import Upload from "../../components/UploadImg/index";
import {
  handleRemoveNotifications,
  seenNotification,
} from "../../slices/notifications";
import findLeaveStatus from "./findLeaveStatus";
const NotifItem = (props) => {
  const {
    unreadNotificationsCount,
    notif,
    fullName,
    dep,
    date,
    code,
    img,
    DAY,
    id,
    dispatch,
  } = props;

  const NEW_AUTHORISATION = code === "new-authorisation";
  const HR_ACCEPT_AUTHORISATION = code === "hr-accept-authorisation";
  const HR_REJECT_AUTHORISATION = code === "hr-reject-authorisation";
  const TL_ACCEPT_AUTHORISATION = code === "tl-accept-authorisation";
  const TL_REJECT_AUTHORISATION = code === "tl-reject-authorisation";

  const HOLIDAY_CONGRATULATION = code === "holiday-congratulation";

  const NEW_LEAVE = code === "new-leave";
  const HR_ACCEPT = code === "hr-accept-leave";
  const HR_REJECT = code === "hr-reject-leave";
  const TL_ACCEPT = code === "tl-accept-leave";
  const TL_REJECT = code === "tl-reject-leave";

  const TL_NEGOTIATE = code === "tl-negotiate-leave";
  const EMP_ACCEPT = code === "emp-accept-negotiation";
  const EMP_REJECT = code === "emp-reject-negotiation";

  findLeaveStatus();
  let departmentName = useSelector((state) => state.department.department);
  const user = useSelector((state) => state.user.fetchMe);
  const isRH = user.role?.code === "RH";

  departmentName = isRH
    ? departmentName
        .filter((depart) => {
          return depart?._id === dep;
        })
        .map((depName) => {
          return depName?.name;
        })
    : user?.department?.name;

  const handlNotifClick = () => {
    dispatch(seenNotification(id));
    dispatch(handleRemoveNotifications(unreadNotificationsCount - 1));
  };
  return (
    <>
      <div className="NotifItem__container" onClick={handlNotifClick}>
        <Upload
          img={img}
          height={"41px"}
          width={"41px"}
          uploadPhoto={true}
          updatedClasse="NotifItem__img"
        />

        <div className="main-item">
          <div className="NotifItem__msg">
            <div
              className={
                notif.seen === true
                  ? "NotifItem__msg_title__seen"
                  : "NotifItem__msg_title "
              }
            >
              <span>{fullName}</span> &nbsp;
              <span>
                {NEW_AUTHORISATION
                  ? " Submit Authorisation"
                  : HR_ACCEPT_AUTHORISATION
                  ? "Accept The Authorisation"
                  : TL_ACCEPT_AUTHORISATION
                  ? "Accept The Authorisation"
                  : HR_REJECT_AUTHORISATION
                  ? "Reject The Authorisation"
                  : TL_REJECT_AUTHORISATION
                  ? "Reject The Authorisation"
                  : EMP_ACCEPT
                  ? " Accept Leave Negotiation  "
                  : NEW_LEAVE
                  ? " Submit A Leave"
                  : HR_ACCEPT
                  ? "Accept The Leave"
                  : TL_ACCEPT
                  ? "Accept The Leave"
                  : HR_REJECT
                  ? "Reject The Leave"
                  : TL_REJECT
                  ? "Reject The Leave"
                  : TL_NEGOTIATE
                  ? " Apply For Negotiation"
                  : EMP_REJECT
                  ? "Reject The Negotiation Request "
                  : HOLIDAY_CONGRATULATION
                  ? `Add A New Holiday `
                  : ""}
              </span>
            </div>

            <span>{`${timeConversion(
              date,
              DAY
            )}  | ${departmentName} Department`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotifItem;
